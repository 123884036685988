<template>
    <v-container fluid>
        <PageHeaderSlot :isLoading="$store.getters.isLoading"> </PageHeaderSlot>
        <v-row>
            <v-col cols="12">
                <Datatable 
                    :isLoading="tableLoading" 
                    :tableHeaders="tableHeaders" 
                    :tableData="tableData" 
                    :page="tablePage"
                    :pageLimit="tableLimit" 
                    :itemTotal="tableItemTotal" 
                    @options-update="onTableOptionsChange($event)"
                    enableSort
                    actionViewRouteLink="AppointmentDetails"
                >
                    <template v-slot:[`item.category`]="{ item }">
                        <span>{{ item.category | appointmentType }}</span>
                    </template>
                </Datatable>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';
import Datatable from '@/components/Datatable.vue';

export default {
    name: 'AppointmentList',
    components: {
        Datatable
    },
    data: () => ({
        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,
        tableSortKey: 'create_date',
        tableSortDir: 'desc',

        // --- static data
        tableHeaders: [
            { text: '建立日期', value: 'create_date', width: 200 },
            { text: '預約類型', value: 'category', sortable: false },
            { text: '預約日期', value: 'date' },
            { text: '預約時間', value: 'time', sortable: false },
            { text: '稱呼', value: 'name', sortable: false },
            { text: '電郵', value: 'email', sortable: false },
            { text: '電話', value: 'phone', sortable: false },
            { text: '', value: 'actionView', width: 130 }
        ],
    }),

    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getAppointmentData() {
            this.tableLoading = true;
            try {
                let payload = {
                    limit: this.tableLimit,
                    page: this.tablePage - 1,
                };

                if (this.tableSortKey !== '' && this.tableSortDir !== '') {
                    payload['sorting'] = this.tableSortKey;
                    payload['direction'] = this.tableSortDir;
                }

                const { data, total } = await this.$Fetcher.GetAppointments(payload);
                this.tableItemTotal = total;
                this.tableData = data.map(item => {
                    return {
                        id: item.id,
                        create_date: item.create_date,
                        category: item.category,
                        date: item.date,
                        time: item.time,
                        name: item.name,
                        email: item.email,
                        phone: item.phone,
                    }
                })
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.tableLoading = false;
            }
        },

        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;

            if (options.sortBy.length > 0) {
                this.tableSortKey = options.sortBy[0];
            } else {
                this.tableSortKey = '';
            }

            if (options.sortDesc.length > 0) {
                if (options.sortDesc[0] === true) {
                    this.tableSortDir = 'desc';
                } else {
                    this.tableSortDir = 'asc';
                }
            } else {
                this.tableSortDir = '';
            }

            this.getAppointmentData();
        },
    },
    async created() {
        this.getAppointmentData();
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>
  